import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import {
  MdAdminPanelSettings,
  MdAirplanemodeActive,
  MdApartment,
  MdAreaChart,
  MdAddAlert,
  MdAttachFile,
  MdBadge,
  MdBallot,
  MdBusiness,
  MdBusinessCenter,
  MdBroadcastOnHome,
  MdCalendarMonth,
  MdChecklist,
  MdChevronLeft,
  MdChevronRight,
  MdDesignServices,
  MdDriveFolderUpload,
  MdDvr,
  MdDynamicFeed,
  MdEditCalendar,
  MdEditNote,
  MdFileUpload,
  MdGroupAdd,
  MdGroupWork,
  MdGroups,
  MdHistory,
  MdOutlineInventory,
  MdInventory,
  MdLaptop,
  MdHome,
  MdLocationOn,
  MdMenuBook,
  MdOutlineAddLocationAlt,
  MdOutlineAlarmOn,
  MdOutlineAssignmentTurnedIn,
  MdOutlineBarChart,
  MdOutlineCalendarMonth,
  MdOutlineCancel,
  MdOutlineContacts,
  MdOutlineEditLocation,
  MdOutlineFeed,
  MdOutlineFilePresent,
  MdOutlineFlagCircle,
  MdOutlineLocationOn,
  MdOutlineMap,
  MdOutlineMiscellaneousServices,
  MdOutlinePhoto,
  MdOutlineWorkspacePremium,
  MdOutlineVideoCall,
  MdPeople,
  MdPolicy,
  MdPermMedia,
  MdPerson,
  MdPerson4,
  MdPoll,
  MdQueryStats,
  MdSettings,
  MdSpellcheck,
  MdSports,
  MdSportsHandball,
  MdSupport,
  MdViewTimeline,
  MdWarning,
  PulseLogoWhite,
  RetainLogoWhite,
  PathwaysLogoWhite,
  CommunitiesLogoWhite,
} from "@teamworksdev/icons";
import {
  NavProvider,
  PrimaryNav,
  SecondaryNav,
  SecondaryNavTitle,
  VerticalNav,
  VerticalNavItem,
  VerticalNavLink
} from "@teamworksdev/react";
import "@teamworksdev/react/dist/index.css";

import LoadingIndicator from "./LoadingIndicator";
import tinycolor from "tinycolor2";
import "./Navigation.css";

const ICON_MAP = {
  add_alert: <MdAddAlert />,
  admin: <MdAdminPanelSettings />,
  airplane: <MdAirplanemodeActive />,
  alert: <MdWarning />,
  area_chart: <MdAreaChart />,
  athlete: <MdSportsHandball />,
  attach_file: <MdAttachFile />,
  badge: <MdBadge />,
  book: <MdMenuBook />,
  business: <MdBusiness />,
  business_center: <MdBusinessCenter />,
  broadcast_on_home: <MdBroadcastOnHome />,
  calendar_month: <MdCalendarMonth />,
  checklist: <MdChecklist />,
  coach: <MdSports />,
  design_services: <MdDesignServices />,
  drive_folder_upload: <MdDriveFolderUpload />,
  dvr: <MdDvr />,
  dynamic_feed: <MdDynamicFeed />,
  edit_calendar: <MdEditCalendar />,
  edit_note: <MdEditNote />,
  fileUpload: <MdFileUpload />,
  group: <MdBallot />,
  groups: <MdGroups />,
  group_add: <MdGroupAdd />,
  history: <MdHistory />,
  home: <MdHome />,
  location_on: <MdLocationOn />,
  laptop: <MdLaptop />,
  inventory: <MdInventory />,
  operations: <MdGroupWork />,
  organization: <MdApartment />,
  outline_add_location_alt: <MdOutlineAddLocationAlt />,
  outline_alarm_on: <MdOutlineAlarmOn />,
  outline_assignment_turned_in: <MdOutlineAssignmentTurnedIn />,
  outline_bar_chart: <MdOutlineBarChart />,
  outline_calendar_month: <MdOutlineCalendarMonth />,
  outline_cancel: <MdOutlineCancel />,
  outline_contacts: <MdOutlineContacts />,
  outline_edit_location: <MdOutlineEditLocation />,
  outline_file_present: <MdOutlineFilePresent />,
  outline_flag_circle: <MdOutlineFlagCircle />,
  outline_inventory: <MdOutlineInventory />,
  outline_location_on: <MdOutlineLocationOn />,
  outline_misc_services: <MdOutlineMiscellaneousServices />,
  outline_feed: <MdOutlineFeed />,
  outline_map: <MdOutlineMap />,
  outline_photo: <MdOutlinePhoto />,
  outline_video_call: <MdOutlineVideoCall />,
  people: <MdPeople />,
  policy: <MdPolicy />,
  perm_media: <MdPermMedia />,
  person: <MdPerson />,
  person_four: <MdPerson4 />,
  poll: <MdPoll />,
  season: <MdViewTimeline />,
  settings: <MdSettings />,
  spell_check: <MdSpellcheck />,
  stats: <MdQueryStats />,
  support: <MdSupport />,
  workspace_premium: <MdOutlineWorkspacePremium />
};
const TOGGLE_ICON_SIZE = 22;
const STORAGE_NAV_EXPANSION_KEY = "$twNavExpanded";
const NAVBAR_STYLE = { width: 270 };

const Navigation = (props) => {
  const { user, school, logoutPaths, links, supportLinks, product, appTargetUrls } = props;
  const impersonating = user.impersonating;
  const mainContainer = useRef(document.getElementById("main-container"));
  const borderContainer = useRef(
    document.getElementById("tw-border-container")
  );
  const borderContainerClass = useRef(
    document.getElementById("tw-border-container").getAttribute("class")
  );

  const defaultOpen = useRef(
    ["true", null].includes(sessionStorage.getItem(STORAGE_NAV_EXPANSION_KEY))
  );
  const [activeLink, setActiveLink] = useState(null);
  const [secondaryNavState, setSecondaryNavState] = useState({
    open: true,
    selection: null
  });

  const toggleSecondaryNav = useCallback(
    () => setSecondaryNavState((p) => ({ ...p, open: !p.open })),
    []
  );

  const onLinkClick = useCallback(
    (augmentedLink) => (event) => {
      if (augmentedLink.isActive || augmentedLink.to === "#") {
        event.preventDefault();
        event.stopPropagation();
      }

      if (augmentedLink.children?.length ?? 0 > 0) {
        setSecondaryNavState({
          open: true,
          selection: augmentedLink
        });
      }
    },
    []
  );

  const getAxleToken = useCallback(
    (product) => {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
        body: JSON.stringify({ product: product })
      };
      return fetch('/auth/axle/token', requestOptions)
        .then(response => response.json())
        .then(data => data);
    },
    []
  );

  /**
   * This removes the rounded corner from the container wrapping the main content when the link is active and has children
   * it also updated the main container background color to match the top color of the navbar
   */
  useEffect(() => {
    if (secondaryNavState.selection?.children && secondaryNavState.open) {
      borderContainer.current?.removeAttribute("class");
    } else {
      borderContainer.current?.setAttribute(
        "class",
        borderContainerClass.current
      );
    }
    mainContainer.current.style.backgroundColor = school.colors.top;
  }, [activeLink, secondaryNavState]);

  const linksWithIcons = useMemo(() => {
    const { pathname } = location;
    let hasActiveNavLink = false;

    // Set active link to empty if we're at one of the settings config pages
    if ([user.edit, school.edit].includes(pathname)) {
      hasActiveNavLink = true;
      setActiveLink({});
    }

    const structuredLinks = links.map((linkVal) => {
      let isActive = pathname === linkVal.to;
      let isHighlighted =
        secondaryNavState.open &&
        linkVal.name === secondaryNavState.selection?.name &&
        !isActive;

      const children =
        linkVal.children?.map((childVal) => {
          isActive = isActive || pathname === childVal.to;

          const child = {
            ...childVal,
            isActive: pathname === childVal.to,
            icon: ICON_MAP[childVal.icon]
          };
          child.onClick = onLinkClick(child);

          return child;
        }) ?? null;

      const augmentedLink = {
        ...linkVal,
        isActive,
        isHighlighted,
        icon: ICON_MAP[linkVal.icon],
        to: linkVal.to ?? "#",
        children
      };
      augmentedLink.onClick = onLinkClick(augmentedLink);

      if (isActive) {
        setActiveLink(augmentedLink);
        hasActiveNavLink = true;

        if ((augmentedLink.children?.length ?? 0) > 0) {
          setSecondaryNavState((prev) =>
            prev.selection
              ? prev
              : {
                  ...prev,
                  selection: augmentedLink
                }
          );
        }
      }
      return augmentedLink;
    });

    // This has to mean we're at a screen that is not part of the navigation.
    // This can happen if there is a bookmarked path that is not accessible
    // via the navigation, or a dead link that still works for some reason
    if (!hasActiveNavLink) {
      setActiveLink({});
    }
    return structuredLinks;
  }, [links, secondaryNavState.selection?.name, secondaryNavState.open]);

  const onLogout = useCallback(() => {
    fetch(logoutPaths.session).then(() => window.location.assign("/"));
  }, [logoutPaths]);

  const onNavStateChanged = useCallback((nextState) => {
    sessionStorage.setItem(STORAGE_NAV_EXPANSION_KEY, `${nextState.expanded}`);
  }, []);

  const productHex = useCallback((product) => {
    switch (product) {
      case 'academics':
        return (<RetainLogoWhite width={36} height={36} />);
      case 'communities':
        return (<CommunitiesLogoWhite width={36} height={36} />);
      case 'pathways':
        return (<PathwaysLogoWhite width={36} height={36} />);
      case 'pulse':
        return (<PulseLogoWhite width={36} height={36} />);
    }
  }, []);

  const navBottomColor = school.colors.bottom ?? "#CC00CC";
  const navTopColor = school.colors.top;
  const colors = useMemo(
    () => ({
      top: navTopColor,
      bottom: navBottomColor
    }),
    [navTopColor, navBottomColor]
  );

  const teamInfo = useMemo(
    () => ({
      avatar: school.avatar,
      organization: school.name
    }),
    [school.avatar, school.name]
  );

  if (activeLink == null) {
    return (
      <div className="loader-container">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <NavProvider
        colors={colors}
        style={NAVBAR_STYLE}
        onNavStateChanged={onNavStateChanged}
      >
        <PrimaryNav
          useReactRouter={false}
          hasAppSwitcher={true}
          app={product}
          apps={school.appTargetUrls}
          onAppChange={async (app, url) => {
            if (app == 'academics' || app == 'pathways' || app == 'pulse' || app == 'communities') {
              window.location.href = `/${app}/dashboard`;
            } else {
              if (impersonating) {
                alert("You cannot switch to this app while impersonating another user.");
                return;
              };
              const {token} = await getAxleToken(product);
              if (token) {
                window.location.href = `${url}?token=${token}`;
              };
            };
          }}
          collapseOnItemClick={false}
          defaultOpen={defaultOpen.current}
          links={linksWithIcons}
          appSpecificHelpSubmenu={supportLinks}
          logo={productHex(product)}
          team={teamInfo}
          urlAccount={user.edit}
          urlSettings={false}
          additionalSettingsLinks={user.links}
          user={user}
          onLogout={onLogout}
        />
        <>
          <div
            style={{
              width:
                secondaryNavState.selection && secondaryNavState.open ? 200 : 0,
              overflow: "hidden",
              position: "relative",
              transition: "width 200ms"
            }}
          >
            <SecondaryNav>
              <SecondaryNavTitle icon={secondaryNavState.selection?.icon}>
                <span style={{ whiteSpace: "nowrap" }}>
                  {secondaryNavState.selection?.name}
                </span>
              </SecondaryNavTitle>
              <VerticalNav useReactRouter={false}>
                {secondaryNavState.selection?.children?.map((childLink) => {
                  return (
                    <VerticalNavItem key={childLink.to}>
                      <VerticalNavLink
                        to={childLink.to}
                        active={location.pathname.startsWith(childLink.to)}
                        onClick={childLink.onClick}
                      >
                        {childLink.icon}
                        <span className="tw-flex-1 tw-ml-2 tw-text-sm tw-truncate">
                          {childLink.name}
                        </span>
                      </VerticalNavLink>
                    </VerticalNavItem>
                  );
                })}
              </VerticalNav>
            </SecondaryNav>
          </div>
          {secondaryNavState.selection && (
            <div
              style={{
                background: navTopColor,
                borderRadius: 5,
                color: "#FFF",
                padding: 0,
                position: "absolute",
                right: -TOGGLE_ICON_SIZE / 2,
                top: 32,
                zIndex: 999
              }}
            >
              <button
                style={{
                  background: "rgba(255, 255, 255, 0.24)",
                  borderRadius: 5,
                  color: "#FFF",
                  padding: 0
                }}
                onClick={toggleSecondaryNav}
              >
                {secondaryNavState.open ? (
                  <MdChevronLeft size={TOGGLE_ICON_SIZE} />
                ) : (
                  <MdChevronRight size={TOGGLE_ICON_SIZE} />
                )}
              </button>
            </div>
          )}
        </>
      </NavProvider>
    </div>
  );
};

export default Navigation;
