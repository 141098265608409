// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import * as Toastr from 'toastr';
import 'toastr/build/toastr.css';

export default class extends Controller {
  static targets = [ "name", "dueDate" ]

  connect() {
  }

  onPostSuccess(event) {
    $('#modal-new-task-window').modal('hide');
    Toastr.success('Task created!');
    this.nameTarget.value = "";
    this.dueDateTarget.value = "";
  }
}
