import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "name", "categories", "subjects", "result", "waiting", "message", "form", "qr", "qrImage" ]
  static values = {
    product: String,
    qrUrl: String,
    location: String,
    appointment: String,
    category: String,
    shouldRefresh: Boolean
  }

  connect() {
    $.cardswipe({
      firstLineOnly: true,
      parsers: [ "generic" ],
      success: this.successLogger,
      error: this.swipeError,
      debug: false
    });
    this.setupSwipe(this);
    if (this.hasQrTarget) {
      this.generateQrCode(this.shouldRefreshValue);
    }
  }

  setupSwipe(_this) {
    $(document).on("scanstart.cardswipe", function() {
      _this.waitingTarget.style.display = 'block';
    });

    $(document).on("success.cardswipe", function(event, data) {
      _this.swipeComplete(data);
    });
  }

  generateQrCode(intiate_refresh) {
    fetch(`${this.productValue === 'pulse' ? '/pulse' : ''}/locations/${this.locationValue}/qr_code?appointment_id=${this.appointmentValue}&category_id=${this.categoryValue}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((data) => {
      this.qrImageTarget.src = data.code;
      if (intiate_refresh == true) {
        this.startRefreshing();
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  startRefreshing() {
    setInterval(() => {
      this.generateQrCode(false);
    }, '60000')
  }

  submitCheckIn(event) {
    let categoryId = event.target.dataset.id;
    let course = event.target.dataset.course;
    let section = event.target.dataset.section;
    var input = document.createElement('input');
    input.type = 'text';
    input.name = 'category_id';
    input.value = categoryId;
    input.id = 'categoryId';
    input.style.display = 'none';
    this.formTarget.appendChild(input);
    if (course) {
      var input2 = document.createElement('input');
      input2.type = 'text';
      input2.name = 'course_subject';
      input2.value = course;
      input2.id = 'courseSubject';
      input2.style.display = 'none';
      this.formTarget.appendChild(input2);
    }
    if (section) {
      var input3 = document.createElement('input');
      input3.type = 'text';
      input3.name = 'course_section';
      input3.value = section;
      input3.id = 'courseSection';
      input3.style.display = 'none';
      this.formTarget.appendChild(input3);
    }
    this.categoriesTarget.style.display = 'none';
    this.subjectsTarget.style.display = 'none';
    $('#kioskResult .kiosk-subjects .btn').remove();
    Rails.fire(this.formTarget, 'submit');
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    var style;
    let existingCategory = document.getElementById("categoryId");
    let existingCourse = document.getElementById("courseSubject");
    let existingSection = document.getElementById("courseSection");
    if (existingCategory) {
      existingCategory.remove();
    }
    if (existingCourse) {
      existingCourse.remove();
    }
    if (existingSection) {
      existingSection.remove();
    }
    style = (data.success ? "alert-success" : "alert-danger");
    if (data.multi == true) {
      this.presentCategories();
    } else if (data.choose_subject == true) {
      this.fetchUserCourseSubjects(data.user_id, data.category_id);
    } else {
      this.updateKioskResult(style, data.message);
    }
  }

  updateKioskResult(style, message) {
    this.nameTarget.value = "";
    this.nameTarget.focus();
    this.messageTarget.classList.remove("alert-info","alert-success","alert-danger");
    this.messageTarget.classList.add(style);
    this.messageTarget.innerHTML = message;
    this.waitingTarget.style.display = 'none';

    if (message !== undefined) {
      this.messageTarget.style.display = 'block';
      setTimeout(() => this.messageTarget.style.display = 'none', 4000)
    }
  }

  presentCategories() {
    this.waitingTarget.style.display = 'none';
    this.categoriesTarget.style.display = 'block';
  }

  fetchUserCourseSubjects(user_id, category_id) {
    fetch(`/students/${user_id}/enrolled_course_subjects`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((data) => {
      this.waitingTarget.style.display = 'none';
      this.categoriesTarget.style.display = 'none';
      if (data.length > 0) {
        $.each(data, function (i, val){
          $('#kioskResult .kiosk-subjects').append(
            `<button type="button" class="btn btn-secondary ml-3" data-id="${category_id}" data-course="${val.name}" data-section="${val.section}" data-action="click->kiosk#submitCheckIn">${val.name}</button>`
          );
        });
      };
      $('#kioskResult .kiosk-subjects').append(
        `<button type="button" class="btn btn-secondary ml-3" data-id="${category_id}" data-course="Other" data-action="click->kiosk#submitCheckIn">Other</button>`
      );
      this.subjectsTarget.style.display = 'block';
    })
    .catch((error) => {
      this.updateKioskResult('alert-danger', error);
    });
  }

  submitting() { this.waitingTarget.style.display = 'block'; }

  swipeComplete(swipe_data) {
    var location_id = this.nameTarget.dataset.id;
    var categoryCount = parseInt(this.nameTarget.dataset.categoryCount);
    var eventId = this.nameTarget.dataset.eventId;
    var checkInCategoryId;
    var checkInCategory;
    var style;
    if (categoryCount == 1) {
      checkInCategory = $('#kiosk .kiosk-categories .btn').first();
      checkInCategoryId = $(checkInCategory).data('id');
    };
    fetch(`${this.productValue === 'pulse' ? '/pulse' : ''}/locations/${location_id}/swipe`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        track_1: swipe_data.line1,
        track_2: swipe_data.line2,
        track_3: swipe_data.line3,
        event_id: eventId,
        category_id: checkInCategoryId
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      style = (data.success ? "alert-success" : "alert-danger");
      if (data.multi == true) {
        this.nameTarget.value = data.unique_id;
        this.presentCategories();
      } else if (data.choose_subject == true) {
        this.fetchUserCourseSubjects(data.user_id, data.category_id);
      } else {
        this.updateKioskResult(style, data.message);
      };
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  successLogger() {
  }

}
